@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0.8rem;
  background: #141414;
}
::-webkit-scrollbar-thumb {
  background: #252525;
  border: 2px solid #141414;
  border-radius: 0.3rem;
}
::-webkit-scrollbar:horizontal {
  height: 0.625rem;
}
::-webkit-scrollbar-thumb {
  background: #252525;
  border: 2px solid #141414;
  border-radius: 0.3rem;
}
::-webkit-scrollbar-corner {
  background: #272727;
}
